<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Candidate Details for {{ candidate.title }}</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>

    <b-row class="mt-1">
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            First Name
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.first_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Middle Name
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.middle_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Last Name
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.last_name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Email
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.email_address"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Phone
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.phone_number"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Gender
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.gender"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Status
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.status"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Skype ID
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.skype_id"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Submitted Date
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.submitted_date"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Date of Birth
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.date_of_birth"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Visa
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.visa.visa_type.type"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Validated
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.visa.validated"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Expiry Date
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.visa.expiration_date"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Interviews
      </h4>
    </div>
    <b-row
      v-for="(item, index) in candidate.interviews"
      :key="index"
      class="mt-1"
    >
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Date
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.interviews[index].date_text"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Time
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.interviews[index].time"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Interviewer Name
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.interviews[index].name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Status
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="candidate.interviews[index].status"
            />
          </dd>
        </dl>
      </b-col>
    </b-row>
    <div
      v-if="candidate.interviews.length == 0"
      class="text-center"
    >
      <p>No interviews scheduled</p>
    </div>
    <hr>

    <div class="d-flex mt-2 mb-1">
      <h4 class="mb-0 ml-50">
        Experience
      </h4>
    </div>
    <b-row
      v-for="experience in candidate.experiences"
      :key="experience"
      :value="experience"
    >
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Name
          </dt>
          <dd class="col-sm-9">
            <span
              class="form-control"
              v-text="experience.name"
            />
          </dd>
        </dl>
      </b-col>
      <b-col cols="6">
        <dl class="row">
          <dt class="col-sm-3 text-right mt-50">
            Experience
          </dt>
          <dd class="col-sm-9">
            <b-input-group append="Year's">
              <span
                class="form-control"
                v-text="experience.years"
              />
            </b-input-group>
          </dd>
        </dl>
      </b-col>
    </b-row>
    <div
      v-if="candidate.experiences.length == 0"
      class="text-center"
    >
      <p>Experience on technology not available</p>
    </div>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Documents
      </h4>
    </div>
    <b-row
      v-if="candidate.documents !== null"
      class="mt-1"
    >
      <b-col
        cols="11"
        offset="1"
      >
        <b-link
          v-for="option in candidate.documents"
          :key="option.id"
          :value="option.id"
          :href="option.document_path"
          :title="option.document"
          target="_blank"
          :class="{'mt-75': option}"
        >
          <b-badge
            variant="primary"
            class="mr-1"
          >
            <span>{{ option.document }}</span>
          </b-badge>
        </b-link>
      </b-col>
    </b-row>
    <div
      v-if="candidate.documents.length == 0"
      class="text-center"
    >
      <p> No Documents available </p>
    </div>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Comments
      </h4>
    </div>
    <b-row class="mt-1">
      <b-col
        v-for="comment in candidate.comments"
        :key="comment"
        cols="6"
      >
        <b-form-group
          label="Comment"
          label-cols-md="3"
          class="text-right"
        >
          <b-form-textarea
            v-model="comment.comment"
            rows="2"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="candidate.comments.length == 0"
      class="text-center"
    >
      <p> No Comments available </p>
    </div>
  </b-card>
</template>

<script>
import router from '@/router/index'
import User from '@/js/user'
import Candidate from '@/js/candidates'

import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormTextarea, BBadge, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BBadge,
    BLink,
  },
  directives: {
  },
  data() {
    return {
      candidate: {
        visa: {
          visa_type: {},
        },
        interviews: [],
      },
    }
  },
  mounted() {
  },
  created() {
    if (!User.profile().job_exist) {
      if (User.profile().invoice_exist) {
        window.location.hash = '#/invoices'
      } else if (User.profile().timesheet_exist) {
        window.location.hash = '#/timesheets'
      } else {
        // Auth.logout()
      }
      return
    }
    Candidate.getCandidateById(this, this.$route.params.id)
  },
  methods: {
    setCandidate(candidate) {
      this.candidate = candidate
      if (candidate.visa == null) {
        this.candidate.visa = {
          type_id: null,
          expiration_date: null,
          validated: false,
          visa_type: {},
        }
      }
      if (candidate.address == null) {
        this.candidate.address = {
          address1: '',
          address2: '',
          city: '',
          zip_id: '',
          state_id: '',
        }
      }
    },
    back() {
      router.go(-1)
    },
  },
  setup() {
  },
}
</script>
